// This file is imported by sass-resource-loader
// And it's automatically added to every *.module.scss file in the project
// @see [root]/.webpack/add-sass-resource-loader.js

// UTILS

@import '../../../assets/scss/utils/material-design-colors';
@import '../../../assets/scss/utils/file-format-colors';
@import '../../../assets/scss/utils/variables';
@import '../../../assets/scss/utils/mixins';

.root {
    background-color: $white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    height: $header-height;
    box-shadow: $header-shadow;
    padding: 0 $header-padding-x;

    .logo {
        min-width: 20rem - 2 * $header-padding-x;

        img {
            height: 4rem;
            width: auto;
        }
    }

    .navigation {
        padding: 0 0 0 0.5rem;
        margin-right: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 3rem;
        border-left: 0.0625rem solid black;
    }

    .navigation-item {
        display: block;
        margin-left: 1rem;
        font-weight: 500;
        text-transform: uppercase;
    }

    .navigation-link {
        padding: 1rem;
        display: inline-block;
        border-radius: 0.5rem;
        text-decoration: none;
        transition: $transition-duration all;
        border: none;

        &:hover,
        &:focus {
            background-color: $hover-background-color;
        }

        &.is-active {
            color: $primary-color;
            font-weight: 700;
        }
    }

    .actions {
        display: flex;
        flex-direction: row;
        align-items: stretch;
    }

    .action {
        margin: 0;
        border-left: 0.0625rem solid $black;
        padding: 0 0.5rem;
        display: flex;
        flex-direction: row;
        align-items: center;

        &:first-child {
            border-left: none;
        }
    }

    .account-button {
        margin: 0 1rem;
    }
}
