// This file is imported by sass-resource-loader
// And it's automatically added to every *.module.scss file in the project
// @see [root]/.webpack/add-sass-resource-loader.js

// UTILS

@import '../../../../assets/scss/utils/material-design-colors';
@import '../../../../assets/scss/utils/file-format-colors';
@import '../../../../assets/scss/utils/variables';
@import '../../../../assets/scss/utils/mixins';

.root {

    .section {
        display: flex;
        flex-direction: row;

        + .section {
            padding-top: 1.5rem;
        }
    }

    .image {
        width: fit-content;
    }

    .attributes {
        flex: 1;

        .item {
            display: flex;
            flex-direction: row;
            font-size: 0.875rem;
            line-height: 1.75rem;

            .label {
                padding-top: 0.5rem;
                color: $grey-500;
                display: inline-block;
                font-size: 0.625rem;
                font-weight: 700;
                line-height: 1rem;
                text-transform: uppercase;
            }

            .value {
                font-weight: 600;
                flex: 1;
                min-height: 1.75rem;
            }
        }
    }

    &.variant-default {

        .section {

            + .section {
                margin-top: 1.5rem;
                border-top: 0.0625rem solid $grey-400;
            }
        }

        .attributes {

            .item {
                flex-wrap: wrap;

                .label {
                    width: 10rem;
                }

                .value {
                    min-width: 20rem;
                }
            }
        }
    }

    &.variant-vertical {

        .attributes {

            .item {
                flex-wrap: wrap;

                .label {
                    width: 10rem;
                }

                .value {
                    min-width: 15rem;
                }
            }
        }
    }
}
