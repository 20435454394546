// This file is imported by sass-resource-loader
// And it's automatically added to every *.module.scss file in the project
// @see [root]/.webpack/add-sass-resource-loader.js

// UTILS

@import '../../../assets/scss/utils/material-design-colors';
@import '../../../assets/scss/utils/file-format-colors';
@import '../../../assets/scss/utils/variables';
@import '../../../assets/scss/utils/mixins';

.menu {
    background-color: $white;
    border-radius: 0.5rem;
    z-index: 100;
    @include box-shadow($dropdown-shadow);

    // ELEMENTS

    ul {
        margin: 0;
        padding: 0.5rem 0;
    }
}

.item {
    margin: 0;
    display: block;
    padding: 0.75rem 1rem;
    cursor: pointer;

    // ELEMENTS

    .icon {
        margin-right: 0.75rem;
    }

    // STATES

    &:hover {
        background-color: $hover-background-color;
    }
}

.button {
    height: 2.25rem;
    line-height: 1.5rem;
    border: none;
    font-size: 1rem;
    background-color: transparent;
    cursor: pointer;
    padding: 0.375rem 0.75rem;
    @include border-radius(1.25rem);
    @include transition(all $transition-duration);

    // ELEMENTS

    .text {
        text-transform: uppercase;
        font-size: 0.875rem;
        font-weight: 600;
    }

    span {

        + span {
            margin-left: 0.5rem;
        }
    }

    // STATES

    &:hover {
        background-color: $hover-background-color;
    }

    // MODIFIERS

    &.size-small {
        font-size: 1rem;
        height: 1.5rem;
        line-height: 1.5rem;
        text-transform: uppercase;

        .icon {

        }

        .text {

        }
    }

    &.is-icon-button {
        font-size: 1.25rem;
        color: $grey-500;
        width: 2rem;
        height: 2rem;
        line-height: 2rem;
        padding: 0;
        @include border-radius(1rem);

        &.size-small {
            height: 1.5rem;
            width: 1.5rem;
            line-height: 1.5rem;
        }
    }
}
