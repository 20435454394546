// This file is imported by sass-resource-loader
// And it's automatically added to every *.module.scss file in the project
// @see [root]/.webpack/add-sass-resource-loader.js

// UTILS

@import '../../../assets/scss/utils/material-design-colors';
@import '../../../assets/scss/utils/file-format-colors';
@import '../../../assets/scss/utils/variables';
@import '../../../assets/scss/utils/mixins';

.root {

    .backdrop {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba($black, 0.2);
        z-index: $z-index-modal-backdrop;
    }

    .modal {
        z-index: $z-index-modal-content;
        position: absolute;
        background-color: $white;
        left: 50%;
        top: 50%;
        height: auto;
        max-width: calc(100vw - 4rem);
        @include border-radius(0.5rem);
        @include box-shadow(0 0 1rem rgba($black, 0.2));
        @include transform(translate(-50%, -50%));
    }

    .close-button {
        position: absolute;
        right: 0.5rem;
        top: 0.5rem;
    }

    .content {
        max-height: calc(100vh - 4rem);
        width: 100%;
        overflow: scroll;
    }

    // MODIFIERS

    &.size-tiny {

        .modal {
            width: 20rem;
        }
    }

    &.size-small {

        .modal {
            width: 40rem;
        }
    }

    &.size-default {

        .modal {
            width: 50rem;
        }
    }

    &.size-large {

        .modal {
            max-width: calc(100vw - 4rem);
        }
    }
}
