// This file is imported by sass-resource-loader
// And it's automatically added to every *.module.scss file in the project
// @see [root]/.webpack/add-sass-resource-loader.js

// UTILS

@import '../../../assets/scss/utils/material-design-colors';
@import '../../../assets/scss/utils/file-format-colors';
@import '../../../assets/scss/utils/variables';
@import '../../../assets/scss/utils/mixins';

.root {
    box-sizing: border-box;

    // ELEMENTS

    .header {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        gap: 2rem;
        margin-bottom: 1.5rem;
    }

    .title {
        font-weight: 300;
        font-size: 1.25rem;
        line-height: 1.75rem;
        padding: 0.75rem 0;
        text-transform: uppercase;

        &.is-string {
            font-weight: 700;
        }
    }

    .header-elements,
    .actions, {
        margin: 0;
        display: flex;
        flex-direction: row;
        padding: 0;
        gap: 1rem;
        align-items: center;
    }

    .actions {
        margin-left: auto;
    }

    .header-element,
    .action {
        margin: 0;
        padding: 0;
        display: inline-block;
    }

    // MODIFIERS

    &.light-background {
        background-color: $white;

        &:last-child {
            flex: 1;
        }
    }

    &.card {
        margin: 0;
        background-color: $white;
        max-width: 100%;
        @include border-radius(1rem);
    }

    // Sizes

    &.size {

        &-exact {
            width: fit-content;
        }

        &-fill {
            flex: 1;
        }

        &-full-width {
            width: 100%;
        }
    }

    // Padding

    &.padding {

        &-default {
            padding: $section-padding-y $section-padding-x;
        }

        &-small {
            padding: $section-padding-y-small $section-padding-x-small;
        }
    }
}
