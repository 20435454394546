// This file is imported by sass-resource-loader
// And it's automatically added to every *.module.scss file in the project
// @see [root]/.webpack/add-sass-resource-loader.js

// UTILS

@import '../../../assets/scss/utils/material-design-colors';
@import '../../../assets/scss/utils/file-format-colors';
@import '../../../assets/scss/utils/variables';
@import '../../../assets/scss/utils/mixins';

.root {
    display: flex;
    height: 100vh;
    width: 100vw;
    flex-direction: column;

    .header {
        z-index: 1;
    }

    .main {
        flex: 1;
        display: flex;
        height: 0;
        flex-direction: row;
        align-content: stretch;
    }

    .sidebar {
        width: $sidebar-width;
        border-right: 0.0625rem solid $grey-300;
        overflow-y: auto;
    }

    .content {
        background-color: $grey-50;
        flex: 1;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow-x: hidden;
    }

    .children {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-content: stretch;
    }
}
