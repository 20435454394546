// COLORS

$primary-color: var(--primary-color, #2699fa);
$hover-background-color: rgba($black, 0.05);

$success-color: $light-green-500;
$warning-color: $orange-500;
$error-color: $red-700;

// FONTS

$font-quicksand: 'Quicksand', sans-serif;
$button-font-family: $font-quicksand;

// TIMINGS

$transition-duration: 0.3s;

// HEADER

$header-shadow: 0 0.25rem 0.25rem rgba(#000000, 0.1);
$header-height: 7rem;
$header-padding-x: 2rem;

// SIDEBAR

$sidebar-width: 18rem;

// CONTEXT BAR

$context-bar-width: 18rem;

// SECTIONS

$section-padding-y: 1.5rem;
$section-padding-x: 2rem;
$section-padding-y-small: 1rem;
$section-padding-x-small: 1rem;

// BUTTONS

$button-spacing: 1rem;
$button-border-radius: 0.5rem;
$button-padding-x: 2rem;
$button-padding-y: 1rem;
$button-padding-x-small: 1rem;
$button-padding-y-small: 0.75rem;
$button-padding-x-large: 2.5rem;
$button-padding-y-large: 1.25rem;

// INPUTS

$input-border-color: $grey-300;
$input-border-color-focus: $grey-500;
$input-border-color-valid: $primary-color;
$input-border-color-invalid: $error-color;
$input-background-color: $white;
$input-background-color-invalid: $red-50;
$input-border-width: 0.0625rem;
$input-border-radius: 0.25rem;
$input-padding-x: 0.75rem;
$input-padding-y: 1rem;
$input-font-size: 1rem;

// DROPDOWN

$dropdown-shadow: 0.125rem 0.125rem 0.5rem rgba(#000000, 0.15);

// Z-INDEX
$z-index-modal-backdrop: 100;
$z-index-modal-content: 200;
$z-index-popup-content: 500;
$z-index-snackbar: 2000;
