// This file is imported by sass-resource-loader
// And it's automatically added to every *.module.scss file in the project
// @see [root]/.webpack/add-sass-resource-loader.js

// UTILS

@import '../../../assets/scss/utils/material-design-colors';
@import '../../../assets/scss/utils/file-format-colors';
@import '../../../assets/scss/utils/variables';
@import '../../../assets/scss/utils/mixins';

.root {
    display: flex;
    flex-direction: row;

    // ELEMENTS

    .icon {
        margin-right: 1rem;
        font-size: 1.25rem;
        line-height: 1.5rem;
    }

    .message {
        font-weight: 600;
        flex: 1;
    }

    //  MODIFIERS

    &.color-success {
        color: $success-color;
    }

    &.color-warning {
        color: $warning-color;
    }

    &.color-error {
        color: $error-color;
    }

    &.color-info {
        color: $grey-400;
    }

    &.color-primary {
        color: $primary-color;
    }
}
