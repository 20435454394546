// This file is imported by sass-resource-loader
// And it's automatically added to every *.module.scss file in the project
// @see [root]/.webpack/add-sass-resource-loader.js

// UTILS

@import '../../../assets/scss/utils/material-design-colors';
@import '../../../assets/scss/utils/file-format-colors';
@import '../../../assets/scss/utils/variables';
@import '../../../assets/scss/utils/mixins';

.root {
    outline: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: $input-font-size;
    font-family: inherit;
    background-color: $input-background-color;
    border-radius: $input-border-radius;
    border: $input-border-width solid $input-border-color;
    text-align: left;
    cursor: pointer;
    @include transition(all 0.3s);

    .button-label {
        flex: 1;
    }

    .arrow-button {
        text-align: center;
        color: $grey-500;
        width: 3rem;
        border-left: 0.0625rem solid $grey-300;
        @include border-radius(0);
    }

    &.variant-default {
        width: 100%;
        min-width: 12rem;
        padding: $input-padding-y 0.5rem $input-padding-y $input-padding-x;
    }

    &.variant-inline {
        min-width: 5rem;
        padding: $input-padding-y * 0.25 $input-padding-x * 0.25
            $input-padding-y * 0.25 $input-padding-x;
        font-size: $input-font-size * 0.75;

        .arrow-button {
            width: 2rem;
        }
    }

    // STATES

    &:focus {
        &:not([aria-disabled='true']) {
            background-color: $hover-background-color;
        }
    }

    &:global {
        &.Mui-focused,
        &.Mui-focusVisible {
            background-color: $hover-background-color;
        }
    }
}

.popup {
    z-index: $z-index-popup-content;
    font-size: 0.875rem;
    display: flex;
    flex-direction: column;
    min-width: 12rem;

    .list {
        background-color: $white;
        border-radius: 0.5rem;
        margin: 0;
        padding: 0.5rem 0;
        max-height: 50vh;
        overflow-y: auto;
        @include box-shadow($dropdown-shadow);
    }

    .option {
        margin: 0;
        padding: 0.5rem 1rem;
        cursor: pointer;

        // STATES

        &:hover {
            &:not([aria-disabled='true']) {
                background-color: $hover-background-color;
            }
        }

        &:focus {
            outline: none;
            &:not([aria-disabled='true']) {
                background-color: $hover-background-color;
            }
        }
    }
}
