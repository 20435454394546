// This file is imported by sass-resource-loader
// And it's automatically added to every *.module.scss file in the project
// @see [root]/.webpack/add-sass-resource-loader.js

// UTILS

@import '../../../assets/scss/utils/material-design-colors';
@import '../../../assets/scss/utils/file-format-colors';
@import '../../../assets/scss/utils/variables';
@import '../../../assets/scss/utils/mixins';

.root {

    // MODIFIERS

    // types

    &.h1 {
        font-size: 1.875rem;
        line-height: 2.375rem;
    }

    &.h2 {
        font-size: 1.1875rem;
        line-height: 1.5625rem;
    }

    &.h3 {
        font-size: 0.875rem;
        line-height: 1.5rem;
        margin-bottom: 0.5rem;
        text-transform: uppercase;
    }

    &.h4 {
        font-size: 0.625rem;
        line-height: 1rem;
    }

    &.p {
        font-size: 1rem;
        line-height: 1.5rem;
    }

    // colors

    &.color-primary {
        color: $primary-color;
    }

    &.color-success {
        color: $primary-color;
    }

    &.color-warning {
        color: $warning-color;
    }

    &.color-error {
        color: $error-color;
    }

    &.color-inherit {
        color: inherit;
    }

    // alignment

    &.align-left {
        text-align: left;
    }

    &.align-center {
        text-align: center;
    }

    &.align-right {
        text-align: right;
    }
}
