// This file is imported by sass-resource-loader
// And it's automatically added to every *.module.scss file in the project
// @see [root]/.webpack/add-sass-resource-loader.js

// UTILS

@import '../../../assets/scss/utils/material-design-colors';
@import '../../../assets/scss/utils/file-format-colors';
@import '../../../assets/scss/utils/variables';
@import '../../../assets/scss/utils/mixins';

.root {
    .button {
        width: 3rem;
        height: 3rem;
        font-family: inherit;
        font-size: 1.125rem;
        line-height: 2.875rem;
        background-color: $white;
        border: 0.0625rem solid $grey-700;
        border-radius: 1.5rem;
        transition: all $transition-duration;
        cursor: pointer;

        &:hover,
        &:focus {
            background-color: $primary-color;
            border-color: $primary-color;
            color: $white;
        }
    }
}
