// This file is imported by sass-resource-loader
// And it's automatically added to every *.module.scss file in the project
// @see [root]/.webpack/add-sass-resource-loader.js

// UTILS

@import '../../../assets/scss/utils/material-design-colors';
@import '../../../assets/scss/utils/file-format-colors';
@import '../../../assets/scss/utils/variables';
@import '../../../assets/scss/utils/mixins';

.root {
    position: relative;

    // ELEMENTS

    .wrapper {
        position: relative;
    }

    .input {
        padding: ($input-padding-y + $input-border-width)
            ($input-padding-x + $input-border-width);
        background-color: $input-background-color;
        border-radius: $input-border-radius;
        border: none;
        outline: none;
        font-size: $input-font-size;
        font-family: inherit;
        width: 100%;
        @include transition(all 0.3s);

        &:focus {
            border-color: $input-border-color-focus;
        }

        &.is-invalid {
            border-color: $input-border-color-invalid;
            background-color: $input-background-color-invalid;
        }
    }

    .start-adornment {
        position: absolute;
        top: 50%;
        left: $input-padding-x;
        @include transform(translateY(-50%));
    }

    // MODIFIERS

    &.border {
        .input {
            padding: $input-padding-y $input-padding-x;
            border: $input-border-width solid $input-border-color;
        }
    }

    &.is-invalid {
        &.border {
            .input {
                border-color: $input-border-color-invalid;
            }
        }

        &:not(.border) {
            .input {
                background-color: $input-background-color-invalid;
            }
        }
    }
}
