$ms-word-color: #2e5b98;
$ms-excel-color: #3a6d45;
$ms-powerpoint-color: #c55737;
$pdf-color: #f40f02;
$zip-color: #f99d1e;

$file-format-colors: (
    'word': $ms-word-color,
    'excel': $ms-excel-color,
    'powerpoint': $ms-powerpoint-color,
    'pdf': $pdf-color,
    'zip': $zip-color,
    'csv': $black,
    'neutral': $black,
);
