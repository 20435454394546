// This file is imported by sass-resource-loader
// And it's automatically added to every *.module.scss file in the project
// @see [root]/.webpack/add-sass-resource-loader.js

// UTILS

@import '../../../assets/scss/utils/material-design-colors';
@import '../../../assets/scss/utils/file-format-colors';
@import '../../../assets/scss/utils/variables';
@import '../../../assets/scss/utils/mixins';

.root {
    background-color: $white;
    position: relative;
    border-left: 0.0625rem solid $grey-300;
    width: 0;
    overflow-y: auto;
    will-change: width;
    @include transition(width 0.3s);

    // ELEMENTS

    .inner {
        position: fixed;
        min-width: $context-bar-width;
        height: calc(100vh - $header-height);
        display: flex;
        flex-direction: column;
    }

    .header {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        border-bottom: 0.0625rem solid $grey-300;
        padding: 0 1rem 0 2rem;

        .icon {
            padding: 1.5rem 1rem 1.5rem 0;
            line-height: 1.5rem;
        }

        .title {
            text-transform: uppercase;
            padding: 1.5rem 0;
            flex: 1;
        }

        .close-button {
            margin-top: 1.25rem;
        }
    }

    .content {
        overflow-y: scroll;
        flex: 1;
    }

    .resizable-handle {
        text-orientation: upright;
        position: absolute;
        left: -0.125rem;
        top: 50%;
        width: 0.5rem;
        height: 2rem;
        cursor: col-resize;
        z-index: 2;
        transform: translateY(-50%);

        &:after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            width: 0.125rem;
            border-left: 0.0625rem solid $grey-300;
            border-right: 0.0625rem solid $grey-300;
        }
    }

    // STATES

    &.is-active {
        width: $context-bar-width;
    }

    &.is-dragging {
        @include transition(none);
    }
}
