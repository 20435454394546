// This file is imported by sass-resource-loader
// And it's automatically added to every *.module.scss file in the project
// @see [root]/.webpack/add-sass-resource-loader.js

// UTILS

@import '../../../assets/scss/utils/material-design-colors';
@import '../../../assets/scss/utils/file-format-colors';
@import '../../../assets/scss/utils/variables';
@import '../../../assets/scss/utils/mixins';

.item {
    display: flex;
    flex-direction: row;
    font-size: 0.875rem;
    line-height: 1.75rem;

    .label {
        width: 10rem;
        display: inline-block;
        font-weight: 700;
        text-transform: uppercase;
    }

    .value {
        font-weight: 500;
        flex: 1;
    }
}
