// This file is imported by sass-resource-loader
// And it's automatically added to every *.module.scss file in the project
// @see [root]/.webpack/add-sass-resource-loader.js

// UTILS

@import '../../../assets/scss/utils/material-design-colors';
@import '../../../assets/scss/utils/file-format-colors';
@import '../../../assets/scss/utils/variables';
@import '../../../assets/scss/utils/mixins';

.root {
    max-width: 90%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .group {
        display: flex;
        align-items: center;
    }

    .slot {
        display: flex;
        position: relative;
        justify-content: center;
        align-items: center;
        transition-property: all;
        transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
        transition-duration: 300ms;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
        border: 1px solid $input-border-color;
        border-radius: $input-border-radius;
        background-color: $input-background-color;
        font-size: $input-font-size;
        padding: $input-padding-x $input-padding-y;
        width: 50px;
        height: 50px;
        margin: 0.25rem;

        &.active {
        }
    }

    .caret {
        display: flex;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        justify-content: center;
        align-items: center;
        pointer-events: none;
    }

    .caretInner {
        animation: blink 1s infinite;
        width: 1px;
        height: 1em;
        background-color: #0a0a0a;
    }

    @keyframes blink {
        0% {
            opacity: 1;
        }
        50% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
}
